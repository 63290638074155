export const matharrall = [
  [
    {
      k: '\\frac{b}{a}',
      v: '\\frac{}{}',
    },
    {
      k: '\\sqrt{x} ',
      v: '\\sqrt{}',
    },
    {
      k: 'x^{y} ',
      v: '{}^{}',
    },
    {
      k: 'ln(x)',
      v: 'ln\\left(\\right)',
    },
    {
      k: '\\log_{(x)}{(y)}',
      v: '\\log_\\left(\\right)\\left(\\right)',
    },
    {
      k: '\\lg{(y)}',
      v: '\\lg\\left(\\right)',
    },
    {
      k: 'a\\frac{c}{b}',
      v: '{}\\frac{}{}',
    },
    {
      k: 'x^{2} ',
      v: '{}^{2}',
    },
    {
      k: '\\sqrt[y]{x}',
      v: '\\sqrt[]{}',
    },
    {
      k: 'sin(x)',
      v: 'sin\\left(\\right)',
    },
    {
      k: 'cos(x)',
      v: 'cos\\left(\\right)',
    },
    {
      k: 'tan(x)',
      v: 'tan\\left(\\right)',
    }, 
    {
      k: 'a_{b}',
      v: '{}_{}',
    },
    {
      k: 'x_{a}^{b}',
      v: 'x_{}^{}',
    },
    {
      k: '\\left|x\\right|',
      v: '\\left|{}\\right|',
    },
    {
      k: 'arcsinx',
      v: 'arcsin\\left(\\right)',
    },
    {
      k: 'arccosx',
      v: 'arccos\\left(\\right)',
    },
    {
      k: 'arctanx',
      v: 'arctan\\left(\\right)',
    },
    {
      k: '\\pi',
      v: '\\pi',
    },
    {
      k: 'e^{x} ',
      v: 'e^{}',
    },
    {
      k: "\\pm",
      v: "\\pm",
    },
    {
      k: '\\overrightarrow{AB}',
      v: '\\overrightarrow{}'
    },
    {
      k: '+\\infty',
      v: '+\\infty'
    },
    {
      k: '-\\infty',
      v: '-\\infty'
    },
    {
      k: '\\left\\{a\\right\\}',
      v: '\\left\\{\\right\\}',
    },
    {
      k: '(a)',
      v: '\\left(\\right)',
    },
    {
      k: '\\left(a,b\\right)',
      v: '\\left(,\\right)'
    },
    {
      k: '\\left[a,b\\right]',
      v: '\\left[,\\right]'
    },
    {
      k: '\\left(a,b\\right]',
      v: '\\left(,\\right]'
    },
    {
      k: '\\left[a,b\\right)',
      v: '\\left[,\\right)'
    },
    {
      k: '+',
      v: '+'
    },
    {
      k: '-',
      v: '-'
    },
    {
      k: '\\times',
      v: '\\times'
    },
    {
      k: '{\\div}',
      v: '{\\div}'
    },
    {
      k: '=',
      v: '='
    },
    {
      k: '\\neq',
      v: '\\neq',
    },
    {
      k: '>',
      v: '>'
    },
    {
      k: '<',
      v: '<'
    },
    {
      k: '\\geq',
      v: '\\geq ',
    },
    {
      k: '\\leq',
      v: '\\leq ',
    }
  ],
  [
    {
      k: '\\alpha',
      v: '\\alpha',
    },
    {
      k: '\\beta',
      v: '\\beta',
    },
    {
      k: '\\gamma',
      v: '\\gamma',
    },
    {
      k: '\\delta',
      v: '\\delta',
    },
    {
      k: '\\theta',
      v: '\\theta',
    },
    {
      k: '\\lambda',
      v: '\\lambda',
    },
    {
      k: '\\rho',
      v: '\\rho',
    },
    {
      k: '\\phi',
      v: '\\phi',
    },
    {
      k: '\\epsilon',
      v: '\\epsilon',
    },
    {
      k: '\\varepsilon',
      v: '\\varepsilon',
    },
    {
      k: '\\zeta',
      v: '\\zeta',
    },
    {
      k: '\\eta',
      v: '\\eta',
    },
    {
      k: '\\vartheta',
      v: '\\vartheta',
    },
    {
      k: '\\iota',
      v: '\\iota',
    },
    {
      k: '\\kappa',
      v: '\\kappa',
    },
    {
      k: '\\mu',
      v: '\\mu',
    },
    {
      k: '\\nu',
      v: '\\nu',
    },
    {
      k: '\\xi',
      v: '\\xi',
    },
    {
      k: 'o',
      v: 'o',
    },
    {
      k: '\\pi',
      v: '\\pi',
    },
    {
      k: '\\varpi',
      v: '\\varpi',
    },
    {
      k: '\\varrho',
      v: '\\varrho',
    },
    {
      k: '\\sigma',
      v: '\\sigma',
    },
    {
      k: '\\varsigma',
      v: '\\varsigma',
    },
    {
      k: '\\tau',
      v: '\\tau',
    },
    {
      k: '\\upsilon',
      v: '\\upsilon',
    },
    {
      k: '\\varphi',
      v: '\\varphi',
    },
    {
      k: '\\chi',
      v: '\\chi',
    },
    {
      k: '\\psi',
      v: '\\psi',
    },
    {
      k: '\\omega',
      v: '\\omega',
    },
  ],
  [
    {
      k: '\\in',
      v: '\\in',
    },
    {
      k: '\\ni',
      v: '\\ni',
    },
    {
      k: '\\notin',
      v: '\\notin',
    },
    {
      k: '\\varnothing',
      v: '\\varnothing',
    },
    {
      k: '\\subset',
      v: '\\subset',
    },
    {
      k: '\\supset',
      v: '\\supset',
    },
    {
      k: '\\subseteq',
      v: '\\subseteq',
    },
    {
      k: '\\supseteq',
      v: '\\supseteq',
    },
    {
      k: '\\Longrightarrow',
      v: '\\Longrightarrow',
    },
    {
      k: '\\Longleftarrow',
      v: '\\Longleftarrow',
    },
    {
      k: '\\Leftrightarrow',
      v: '\\Leftrightarrow',
    },
    {
      k: '\\forall',
      v: '\\forall',
    },
    {
      k: '\\exists',
      v: '\\exists',
    },
    {
      k: '∄',
      v: '∄',
    },
    {
      k: '\\cap',
      v: '\\cap'
    },
    {
      k: '\\cup',
      v: '\\cup'
    }
  ],
  [
    {
      k: '^\\circ度',
      v: '^\\circ',
    },
    {
      k: '\\prime分',
      v: '\\prime'
    },
    {
      k: '\\prime\\prime秒',
      v: '\\prime\\prime'
    },
    {
      k: '\\cong',
      v: '\\cong'
    },
    {
      k: '\\sim',
      v: '\\sim'
    },
    {
      k: '\\triangle',
      v: '\\triangle'
    },
    {
      k: '\\angle',
      v: '\\angle '
    },
    {
      k: '//',
      v: '//'
    },
    {
      k: '\\perp',
      v: '\\perp'
    }
  ]
]