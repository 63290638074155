<template>
  <div class="container" ref="mathjaxContainer">
    <div class="question-item" v-for="(item, index) in questionList" :key="index">
      <h3 class="b">{{figure[index]}}、{{item.origin_cate_name}}题（共{{item.questions.length}}题
        <span v-if="isShowScore">，总共{{getAllScrore(item)}}分</span>
      ）</h3>
      <div 
        v-for="(childItem, childIndex) in item.questions" 
        class="subquestion-item"
        :class="{active: index === activeIndex && childIndex === activeSubIndex}"
        :key="childIndex"
      >
        <!-- 单选题 -->
        <div v-if="item.question_type === 101">
          <div class="subquestion-title fr">
            <div class="fr">
              {{childItem.order_id}}、
              <span class="title" v-html="childItem.question"></span>
            </div>
            <div class="sub-right" v-if="isShowScore">分值：{{childItem.score}}</div>
          </div>
          <el-radio-group 
            v-model="answers[getOriginIndex(childItem.question_id, null)].value" 
            @change="handleSelected(index, childIndex, $event)"
          >
            <el-radio
              :label="sonItem.option_id" 
              :key="sonItem.option_id" 
              v-for="(sonItem, sonIndex) in childItem.content"
            >
              <span>{{answerOptions[sonIndex]}}、</span><span v-html="sonItem.value"></span>
            </el-radio>
          </el-radio-group>
        </div>
        <!-- 判断题 -->
        <div v-if="item.question_type === 200">
          <div class="subquestion-title fr">
            <div class="fr">
              {{childItem.order_id}}、
              <span class="title" v-html="childItem.question"></span>
            </div>
            <div class="sub-right" v-if="isShowScore">分值：{{childItem.score}}</div>
          </div>
          <el-radio 
            v-model="answers[getOriginIndex(childItem.question_id, null)].value" 
            label="1"
            @change="handleSelectedJudge(index, childIndex, $event)"
          >正确</el-radio>
          <el-radio 
            v-model="answers[getOriginIndex(childItem.question_id, null)].value" 
            label="0"
            @change="handleSelectedJudge(index, childIndex, $event)"
          >错误</el-radio>
        </div>
        <!-- 填空题 -->
        <div v-else-if="item.question_type === 300">
          <div class="subquestion-title fr">
            <div class="fr sub-left">
              {{childItem.order_id}}、
              <span>
                <div class="question_box" v-for="(sonItem, sonIndex) in childItem.question" :key="sonIndex">
                  <span class="question_item" v-for="(grandchildItem, grandchildIndex) in sonItem" :key="grandchildIndex">
                    <span v-html="grandchildItem.val"></span>
                    <!-- v-if 条件：字符串split 参数出现在头尾 会产生空字符串 -->
                    <span v-if="grandchildIndex != sonItem.length-1">
                      <latex-gap-input
                        :childAnswers.sync="answers[getOriginIndex(childItem.question_id, childItem.content[grandchildItem.idx - sonIndex].option_id)]"
                        :parentAnswers.sync="answers"
                        :key="childItem.question_id + (grandchildItem.idx - sonIndex)"
                        :temps="childItem.content[grandchildItem.idx - sonIndex].latex_template"
                        @handleBlur="$emit('handleBlur')"
                        ref="latexBox"
                      />
                    </span>
                  </span>
                </div>
              </span>
            </div>
            <div class="sub-right" v-if="isShowScore">分值：{{childItem.score}}</div>
          </div>
        </div>
        <!-- 解答题 -->
        <div v-else-if="item.question_type === 400">
          <div class="subquestion-title fr" :class="{jieda: childItem.has_child == 1}">
            <div class="sub-left fr">
              {{childItem.order_id}}、
              <span>
                <!-- 解答题无子题 -->
                <div class="question_box" v-for="(sonItem, sonIndex) in childItem.question" :key="sonIndex" v-if="childItem.has_child == 0">
                  <span class="question_item" v-for="(grandchildItem, grandchildIndex) in sonItem" :key="grandchildIndex">
                    <span v-html="grandchildItem.val"></span>
                    <span v-if="grandchildIndex != sonItem.length-1">
                      <latex-gap-input
                        :childAnswers.sync="answers[getOriginIndex(childItem.question_id, childItem.content[grandchildItem.idx - sonIndex].option_id)]"
                        :parentAnswers.sync="answers"
                        :key="childItem.question_id + (grandchildItem.idx - sonIndex)"
                        :temps="childItem.content[grandchildItem.idx - sonIndex].latex_template"
                        @handleBlur="$emit('handleBlur')"
                      />
                    </span>
                  </span>
                </div>
              </span>
            </div>
            <el-tooltip effect="dark" :content="childItem.sub_questions.map(v => v.score).join('，')" placement="top">
              <div class="sub-right" v-if="childItem.has_child == 0 && isShowScore">分值：
                <span>{{childItem.score}}</span>
              </div>
            </el-tooltip>
            
            
            <!-- 解答题有子题 -->
            <div v-if="childItem.has_child == 1" style="margin-top: 3px;width: 100%">
              <span style="line-height: 1.6" v-html="childItem.question"></span>
              <div v-for="(ssonItem, ssonIndex) in childItem.sub_questions" :key="ssonIndex" class="son-box">
                <div class="question_box fr" v-for="(sonItem, sonIndex) in ssonItem.question" :key="sonIndex">
                  <div>
                    <span v-if="sonIndex === 0">{{`${childItem.order_id}-${ssonIndex+1}`}}、</span>
                    <span v-else style="opacity: 0">{{`${childItem.order_id}-${ssonIndex+1}`}}、</span>
                    <span v-html="childItem.val"></span>
                    <span class="question_item" v-for="(grandchildItem, grandchildIndex) in sonItem" :key="grandchildIndex">
                      <span v-html="grandchildItem.val"></span>
                      <span v-if="grandchildIndex != sonItem.length-1">
                        <latex-gap-input
                          :childAnswers.sync="answers[getOriginIndex(ssonItem.question_id, ssonItem.content[grandchildItem.idx - sonIndex].option_id)]"
                          :parentAnswers.sync="answers"
                          :key="childItem.question_id + (grandchildItem.idx - sonIndex)"
                          :temps="ssonItem.content[grandchildItem.idx - sonIndex].latex_template"
                          @handleBlur="$emit('handleBlur')"
                        />
                      </span>
                    </span>
                  </div>
                  <el-tooltip effect="dark" :content="ssonItem.sub_questions.map(v => v.score).join('，')" placement="top">
                    <div class="sub-right" v-if="sonIndex === 0 && isShowScore">分值：
                      <span>{{ssonItem.score}}</span>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="answerSheetBtn">

    </slot>
    <div class="daohang fc" v-if="app.isPad" @click="showAnswerSheet">
      <img src="../assets/images/datika.png" alt="">
      <span>答题卡</span>
    </div>
  </div>
</template>

<script>
import { ANSWER_OPTIONS, FIGURE } from '@/utils/config';
import LatexGapInput from './latexGapInput.vue';
export default {
  data() {
    return {
      // 所有答案选项
      answerOptions: ANSWER_OPTIONS,
      questionList: this.initQuestionList,
      // 一、二、三...
      figure: FIGURE,
      checkList: [],
      answers: Object.assign([], this.answer)
    };
  },
  props: {
    initQuestionList: {
      type: Array,
      default: () => [],
    },
    answer: {
      type: Array,
      default: () => []
    },
    activeIndex: {
      type: Number
    },
    activeSubIndex: {
      type: Number
    },
    isHideSheet: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number
    }
  },
  components: {
    LatexGapInput,
  },
  inject: ['app'],
  watch: {
    answers(val) {
      this.$emit('update:answer', val)
    }
  },
  computed: {
    // 是否显示分值
    isShowScore() {
      return this.type === 1
    }
  },
  mounted() {
    // 渲染mathjax公式
    this.$nextTick(() => {
      if (this.commonsVariable.isMathjaxConfig === false) {
        // 如果：没有配置MathJax
        this.commonsVariable.initMathjaxConfig();
      }
      let elem = this.$refs.mathjaxContainer;
      this.commonsVariable.MathQueue(elem);
    });
  },
  methods: {
    /**选择判断题 */
    handleSelectedJudge(index, childIndex, v) {
      let elem = document.querySelectorAll('.mathjax2')[childIndex]
      this.$emit('saveQuestion')
      if (elem) {
        elem.innerHTML = `${v === '0' ? '错误' : '正确'}`
      }
    },
    /**显示答题卡 */
    showAnswerSheet() {
      this.$emit('update:isHideSheet', !this.isHideSheet)
    },
    getOriginIndex(question_id, option_id) {
      if (option_id) { // 非选择题
        return this.answers.findIndex(v => v.question_id === question_id && v.option_id === option_id)
      } else { // 选择题
        return this.answers.findIndex(v => v.question_id === question_id)
      }
    },
    // 获取单个题型的总分值
    getAllScrore({questions}) {
      return questions.reduce((sum, e) => sum + e.score, 0)
    },
    /**设置选择题答案 */
    handleSelected(index, childIndex, v) {
      let questionObj = this.questionList[index].questions[childIndex]
      let findIdx = questionObj.content.findIndex(cv => cv.option_id == v)
      let elem = document.querySelectorAll('.mathjax')[childIndex]
      this.$emit('saveQuestion')
      if (elem) {
        elem.innerHTML = `(&#12288;&#12288;${this.answerOptions[findIdx]}&#12288;&#12288;)`
      }
    } 
  },
};
</script>

<style lang="scss" scoped>
::v-deep .mathjax2 {
  color: #68B8E6;
  border-bottom: 1px solid #dadada;
  margin: 0 5px;
}
.son-box {
  margin-top: 20px;
  margin-left: 2em;
  justify-content: space-between;
  align-items: center;
}
.container {
  background: white;
  width: 100%;
  padding: 28px 50px 40px 40px;
  border-bottom-left-radius: 25px;
  .question-item {
    margin-top: 60px;
    &:first-child {
      margin-top: 0;
    }
    h3 {
      font-size: 18px;
      color: #333;
      margin-bottom: 40px;
      margin-left: 10px;
    }
    .subquestion-item {
      margin-bottom: 65px;
      &:last-child {
        margin-bottom: 0;
      }
      .subquestion-title {
        font-size: 16px;
        color: #555;
        margin-bottom: 40px;
        justify-content: space-between;
        align-items: baseline;
        .title {
          line-height: 1.6;
        }
        .fr {
          align-items: baseline;
        }
        &.jieda {
          justify-content: flex-start;
        }
        .sub-no {
          position: relative;
          top: 3px;
        }
        .question_box {
          line-height: 1.85;
          justify-content: space-between;
        }
        .sub-left {
          align-items: baseline;
        }
        .sub-right {
          flex-shrink: 0;
          padding-left: 20px;
          .right {
            color: #34B979;
          }
          .error {
            color: red;
          }
        }
      }
      padding: 10px;
      border-radius: 15px;
      border: 1px solid transparent;
      &.active {
        animation: myanimation 1.2s;
        @keyframes myanimation {
          0% {
            background: transparent;
          }
          50% {
            background: rgb(83, 159, 255);
          }
          100% {
            background: transparent;
          }
        }
      }
    }
    .el-radio-group {
      display: flex;
      flex-direction: column;
      & > label {
        display: flex;
        align-items: center;
        min-height: 40px;
        line-height: 100%;
        ::v-deep p {
          line-height: 100%;
          font-size: 16px;
        }
        ::v-deep .el-radio__label {
          display: flex;
          align-items: center;
        }
      }
    }
    .el-checkbox {
      display: flex;
      align-items: center;
      min-height: 40px;
      ::v-deep .el-checkbox__label {
        display: flex;
        align-items: center;
      }
      ::v-deep p {
        line-height: 100%;
        font-size: 16px;
      }
    }
  }
  .daohang {
    position: fixed;
    right: 0;
    background: #45BEF1;
    top: 50%;
    transform: translateY(-50%);
    width: 39px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    align-items: center;
    font-size: 16px;
    color: white;
    cursor: pointer;
    img {
      width: 19px;
      padding: 8px 0;
    }
    span {
      writing-mode: vertical-lr;
      padding-bottom: 8px;
      letter-spacing: 1px;
    }
  }
}

.main {
  width: 100%;
  min-height: 500px;
  margin: 50px auto;
  justify-content: space-around;
}
.gap-input {
  position: relative;
  margin: 0 5px;
  .answer {
    min-width: 228px;
    min-width: 88px;
    border: none;
    border-bottom: 1px solid gray;
    text-align: center;
    padding: 0 5px;
    box-sizing: border-box;
    box-shadow: none;
    min-height: 22px;
    display: inline-block;
  }
  .latexBox {
    width: 468px;
    flex-wrap: wrap;
  }

  .gap-input-option {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    border-radius: 10px;
    background: #d5d8de;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 12px;
    padding: 20px;
    .sjx {
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      border-left: 1px solid rgb(222, 222, 222);
      border-top: 1px solid rgb(222, 222, 222);
      background: transparent;
      position: absolute;
      left: 48%;
      transform: rotate(45deg) translateX(-50%);
      top: -2px;
      background: white;
      display: none;
    }
    & > div > span {
      color: #555;
      font-size: 16px;
      border-radius: 6px;
      cursor: pointer;
      min-width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 5px;
      font-weight: bold;
      background: #ffffff;
      box-shadow: 0 1px 0px #8e98ac;
      height: 43px;
      width: 68px;
    }
  }
}
</style>