<template>
  <div class="do-examination" v-loading="loading" @click="handleBlur">
    <slot 
      name="nav" 
      :paperObj="paperObj" 
      :questionSize="questionSize"
      :diff="diff"
    />
    <div class="fr footer">
      <Question
        ref="questons"
        v-if="questionList.length"
        :initQuestionList="questionList"
        :answer.sync="answers"
        :activeIndex="activeIndex"
        :activeSubIndex="activeSubIndex"
        :isHideSheet.sync="isHideAnswerSheet"
        :type="paperObj.type"
        @handleBlur="handleBlur"
        @saveQuestion="saveQuestion"
      >
        <template slot="answerSheetBtn" v-if="app.isPad">
          <slot name="answerSheetBtn" :showSubmitPaperDialog="showSubmitPaperDialog" />
        </template>
      </Question>
      <AnswerSheet
        v-show="app.isPad ? isHideAnswerSheet : true"
        :paperObj="paperObj" 
        :size="questionSize" 
        :questionList="questionList"
        :answers="answers"
        @handleFocusQuestion="handleFocusQuestion"
        :remainCount.sync="remainCount"
      >
        <template slot="answerSheetHeader">
          <slot name="answerSheetHeader" :duration="duration" />
        </template>
        <template slot="answerSheetBtn">
          <slot name="answerSheetBtn" :showSubmitPaperDialog="showSubmitPaperDialog" />
        </template>
      </AnswerSheet>
    </div>
  </div>
</template>

<script>
import Question from 'comps/question'
import AnswerSheet from 'comps/answerSheet.vue'

import {paperDetail, start_new, submitPaper, submitQuestion} from 'api'
import { ERROR_CODE, ANSWER_OPTIONS } from 'utils/config'
import {deepClone} from 'utils/util'

export default {
  data() {
    return {
      answerOptions: ANSWER_OPTIONS,
      paperObj: {},
      // 试卷难度
      diff: 0,
      // 试卷时长
      duration: 0,
      // 题目列表
      questionList: [],
      loading: false,
      id: null,
      answers: [],
      activeIndex: -1,
      activeSubIndex: -1,
      isReq: true,
      // 默认隐藏答题卡
      isHideAnswerSheet: false,
      // 剩余题数
      remainCount: 1,
      // 页面进入的开始时间
      startTime: null,
      // 轮询请求次数
      pollCnt: 0,
      countIndex: 0
    }
  },
  inject: ['app'],
  components: {
    Question,
    AnswerSheet,
  },
  mounted () {
    this.queryPaperDetail()
    this.watchPopstate()
  },
  beforeDestroy() {
    this.removePopstateEvent()
    clearTimeout(this.timer2)
    clearTimeout(this.timer)
    clearTimeout(this.timer3)

    if (this.pollCnt > 0) {
      this.saveQuestion()
    }
  },
  computed: {
    // 计算习题数量
    questionSize() {
      let size = 0
      this.questionList.forEach(v => {
        size += v.questions.length
      })
      return size 
    }
  },
  methods: {
    // 移除事件
    removePopstateEvent() {
      window.removeEventListener('popstate', this.handlePopstate, false)
    },
    handlePopstate() {
      this.$confirm('当前页面数据未保存，确定要离开？', '提示', { type: 'warning' }).then(() => {
        this.removePopstateEvent()
        this.$router.go(-1)
      }).catch(() => {
        history.pushState(null, null, document.URL)
      });
    },
    // 监听路由离开
    watchPopstate() {
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL)
        window.addEventListener('popstate', this.handlePopstate, false)
      }
    },
    // 手动去掉占位符
    getOriginAnswer(obj) {
      let tmpStr = '^{ }'
      obj.value = obj.value.replaceAll('，', ',').replaceAll('\\ ', '').replaceAll(' ', '')
      let index = obj.value.lastIndexOf(tmpStr)
      if (index === -1) {
        tmpStr = '_{ }'
        index = obj.value.lastIndexOf(tmpStr)
        if (index === -1) {
          return obj
        } else {
          if (index + tmpStr.length === obj.value.length) {
            obj.value = obj.value.substr(0, index)
            return obj
          }
          return obj
        }
      }
      if (index + tmpStr.length === obj.value.length) {
        obj.value = obj.value.substr(0, index)
        return obj
      }
      return obj
    },
    // 保存题目的答案
    async saveQuestion() {
      let _answers = deepClone(this.answers)
      _answers = _answers.map(v => ({
        ...v, 
        option_id: v.type === 101 ? v.value : v.option_id
      }))

      // 处理^{}占位符问题
      _answers = _answers.map(v => [300, 400].indexOf(v.type) >= 0 ? this.getOriginAnswer(v) : v)
      _answers = JSON.stringify(_answers)

      if (this.copyAnswers === _answers) {
        return
      }
      this.copyAnswers = _answers
      this.isReq = false
      await submitQuestion({
        report_id: this.id,
        answers: _answers
      })
      this.isReq = true
    },
    handleFocusQuestion(index, childIndex) {
      let el = this.$refs.questons.$el
      let questionComp = el.querySelectorAll('.question-item')[index]
      let subquestionComp = questionComp.querySelectorAll('.subquestion-item')[childIndex]
      subquestionComp.scrollIntoView()
      this.activeIndex = index
      this.activeSubIndex = childIndex
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.activeIndex = -1
        this.activeSubIndex = -1
      }, 1200)
    },
    // 隐藏选择模板
    handleBlur() {
      let hasShowed = false
      this.answers = this.answers.map(v => {
        if (v.show) {
          v.show = false
          hasShowed = true
        }
        return v
      })

      if (hasShowed) {
        if (this.isReq) {
          this.saveQuestion()
        } else {
          this.pollCnt++
        }
      }
    },
    /**删除表格内的字符串 */
    getOriginQuestion(str) {
      let res = str.split('<table')
      for (let i in res) {
        if (res[i].indexOf('</table>') >= 0) {
          res[i] = res[i].replace(/\r\n/g, '').replace(/\n/g, '')    
        }
      }
      return res.join('<table')
    },
    // 初始化答案选项
    initAnswers() {
      let errIds = []
      this.questionList = this.questionList.map(v => {
        if (v.question_type === 101) { // 单选
          v.questions = v.questions.map(cv => {
            let _client_answer = cv.client_answer

            this.answers.push({
              question_id: cv.question_id,
              type: v.question_type,
              option_id: _client_answer.length !== 0 ? _client_answer[0].option_id : '',
              value: _client_answer.length !== 0 ? _client_answer[0].option_id : ''
            })
            cv.question = cv.question.replace('(　　　　　　)', `<span class="mathjax">(&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;)</span>`)
            cv.question = cv.question.replace('(　　　　　)', `<span class="mathjax">(&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;)</span>`)
            
            return cv
          })
        } else if (v.question_type === 200) { // 判断
          v.questions = v.questions.map(cv => {
            let _client_answer = cv.client_answer

            this.answers.push({
              question_id: cv.question_id,
              type: v.question_type,
              option_id: cv.content[0].option_id,
              value: _client_answer.length !== 0 ? _client_answer[0].value : ''
            })
            cv.question = cv.question.replace('(　　　　　　)', `<span class="mathjax2">&#12288;&#12288;</span>`)
            cv.question = cv.question.replace('(　　　　　)', `<span class="mathjax2">&#12288;&#12288;</span>`)
            
            return cv
          })
        } else if (v.question_type === 300) { // 填空
          v.questions = v.questions.map(cv => {
            let _client_answer = cv.client_answer

            cv.content.forEach((scv, sci) => {
              this.answers.push({
                question_id: cv.question_id,
                option_id: scv.option_id,
                type: v.question_type,
                value: _client_answer.length !== 0 ? _client_answer[sci].value : ''
              })
            })

            let idx = -1
            cv.question = this.getOriginQuestion(cv.question)
              .replace(/<p>/g, '<span class=\"line\">')
              .replace(/<\/p>/g, '<\/span>')
              .split('\n')
              .map((item)=>{
                return item.split('(　　　　　　)').map(scv => {
                  idx++
                  return {
                    val: scv,
                    idx
                  }
                })
              })
            if (cv.content.length + 1 !== cv.question[0].length) {
              errIds.push(cv.question_id)
            }
            return cv
          })
        } else if (v.question_type === 400) { // 解答
          v.questions = v.questions.map(cv => {
            let idx = -1
            if (cv.has_child == 1) { // 有子题
              cv.sub_questions = cv.sub_questions.map((scv, sci) => {

                scv.content.forEach((sscv, ssci) => {
                  this.answers.push({
                    question_id: scv.question_id, // 子题干的id
                    option_id: sscv.option_id,
                    type: v.question_type,
                    parent_id: cv.question_id, // 子题的主题干的id
                    value: scv.client_answer.length !== 0 ? scv.client_answer[ssci].value : '',
                  })
                })

                idx = -1
                scv.question = this.getOriginQuestion(scv.question)
                  .replace(/<p>/g, '<span class=\"line\">')
                  .replace(/<\/p>/g, '<\/span>')
                  .split('\n')
                  .map(item => {
                    return item.split('(　　　　　　)').map(sscv => {
                      idx++
                      return {
                        val: sscv,
                        idx
                      }
                    })
                  })

                scv.order_id = `${cv.order_id}-${sci+1}`

                if (scv.content.length + 1 !== scv.question[0].length) {
                  errIds.push(scv.question_id)
                }

                return scv
              })
            } else { // 无子题
              let _client_answer = cv.client_answer

              cv.content.forEach((scv, sci) => {
                this.answers.push({
                  question_id: cv.question_id,
                  option_id: scv.option_id,
                  type: v.question_type,
                  value: _client_answer.length !== 0 ? _client_answer[sci].value : '',
                })
              })

              cv.question = this.getOriginQuestion(cv.question)
                .replace(/<p>/g, '<span class=\"line\">')
                .replace(/<\/p>/g, '<\/span>')
                .split('\n')
                .map(item => {
                  return item.split('(　　　　　　)').map(scv => {
                    idx++
                    return {
                      val: scv,
                      idx
                    }
                  })
                })

              if (cv.content.length + 1 !== cv.question[0].length) {
                errIds.push(cv.question_id)
              }
            }
            return cv
          })
        }
        return v
      })
      this.$nextTick(() => {
        this.questionList.forEach((v, i) => {
          if (v.question_type === 101) { // 设置选择题的答案
            v.questions.forEach((cv, ci) => {
              if (cv.client_answer.length) {
                let findIdx = cv.content.findIndex(scv => scv.option_id == cv.client_answer[0].option_id)
                if (findIdx >= 0) {
                  let elem = document.querySelectorAll('.mathjax')[ci]
                  if (elem) {
                    elem.innerHTML = `(&#12288;&#12288;${this.answerOptions[findIdx]}&#12288;&#12288;)`
                  }
                }
              }
            })
          } else if (v.question_type === 200) { // 判断题
            v.questions.forEach((cv, ci) => {
              if (cv.client_answer.length) {
                let val = cv.client_answer[0].value
                if (val === '1' || val === '0') {
                  let elem = document.querySelectorAll('.mathjax2')[ci]
                  if (elem) {
                    elem.innerHTML = `${val == 1 ? '正确' : '错误'}`
                  }
                }
              }
            })
          }
        })
      })

      this.answers = this.answers.map(v => ({...v, show: false}))
      if (errIds.length) {
        console.error(`【${errIds.join('、')}】录入答案异常！`)
      }
    },
    // 提交试卷
    async submitPaper() {
      if (this.pollCnt > 0) {
        await this.saveQuestion()
      }
      // 提交答卷
      let res = await submitPaper({report_id: this.id})
      if (res.error === 0) {
        this.$message.success('试卷提交成功！')
        clearTimeout(this.timer3)
        this.timer3 = setTimeout(() => {
          this.$router.push({
            path: '/paperfenxi',
            query: {
              id: this.id
            }
          })
        }, 3000)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 显示提交试卷的弹窗
    showSubmitPaperDialog(title) {
      let message = `${this.remainCount > 0 ? '当前试卷还未完成，' : ''}是否确认${title}`
      this.$confirm(message, '提示', {
        confirmButtonText: '确认提交',
        cancelButtonText: '继续做题',
        type: 'warning'
      }).then(this.submitPaper).catch(() => {});
    },
    async queryPaperDetail() {
      this.id = this.$route.query.id
      this.diff = parseInt(this.$route.query.diff)
      this.loading = true
      let {error, data} = await paperDetail({report_id: this.id})
      
      if (error === ERROR_CODE) {
        this.paperObj = data;
        this.questionList = this.paperObj.question_list || []
        // 只加载单选、判断、填空和解答
        this.questionList = this.questionList.filter(v => (
          v.question_type === 101 || 
          v.question_type === 200 ||
          v.question_type === 300 || 
          v.question_type === 400
        ))
        this.initAnswers()
        
        // 开始答卷
        let resp = await start_new({report_id: this.id})
        this.loading = false
        if (resp.error === 0) {
          let obj = resp.data || {}
          if (obj.test_duration_second !== undefined) {
            this.duration = obj.test_duration_second
          } else {
            this.duration = this.paperObj.test_duration * 60
          }
        }
        if (this.duration !== 0) {
          this.startTimeout(0)
        }
      }
    },
    // 开启定时器
    startTimeout(interval) {
      if (!this.startTime) {
        this.startTime = new Date().getTime()
      }
      clearTimeout(this.timer2)
      this.timer2 = setTimeout(() => {
        this.duration--
        if (this.duration === 0) {
          clearTimeout(this.timer2)
          this.$message.warning('答卷时间到，系统将自动提交试卷')
          this.submitPaper()
        } else {
          let endTime = new Date().getTime()
          this.countIndex++
          let deviation = endTime - this.startTime - this.countIndex * 1000
          this.startTimeout(deviation)
        }
      }, 1000 - interval)
    }
  },
}
</script>

<style lang="scss" scoped>
.do-examination {
  .footer {
    margin-top: 12px;
  }
}
::v-deep table {
  margin-top: 5px;
  margin-bottom: 4px;
  td {
    border: 1px solid grey;
  }
}
</style>