<template>
  <span class="gap-input" ref="child">
     <div class="input-container" v-show="!temps.length">
      <span>{{childAnswers.value}}</span>
      <input 
        type="text" 
        class="input" 
        v-model="childAnswers.value" 
        @click.stop="() => null" 
        @focus="handleInputFocus"
        @blur="handleInputBlur"
      >
    </div>
    <span
      class="answer"
      v-show="temps.length"
      v-html="answers.value"
      @click.stop="handleFocus"
    ></span>
    <div
      class="gap-input-option"
      :class="{isPad: app.isPad}"
      v-show="childAnswers.show && temps.length"
      @click.stop="() => null"
    >
      <div class="gap-left fr" v-if="app.isPad">
        <span @click="$emit('handleBlur')">＞</span>
        <span :class="{active: activeIndex === 0}" @click="handleSelected(0)">常用字符</span>
        <span :class="{active: activeIndex === 1}" @click="handleSelected(1)">希腊字母</span>
        <span :class="{active: activeIndex === 2}" @click="handleSelected(2)">集合与逻辑</span>
        <span :class="{active: activeIndex === 3}" @click="handleSelected(3)">几何</span>
      </div>
      <div v-for="(parItem, idx) in obj.temps" :key="idx" class="fr latexBox" v-show="parItem.eq" :class="{isPad: app.isPad}">
        <span class="m1" @click="handleSelectedTemp(item.v, idx)" :key="item.v" v-for="item in parItem">{{ item.k }}</span>
      </div>
      <p class="xlmathbtn" v-if="!app.isPad">
        <a @click="addxlmath(obj.toggleflagxl)">
          希腊字母<i :class="[obj.toggleflagxl ? 'el-icon-arrow-up' : 'el-icon-arrow-down',]"></i>
        </a>
        <a @click="addjhmath(obj.toggleflagjh)">
          集合与逻辑<i :class="[obj.toggleflagjh ? 'el-icon-arrow-up' : 'el-icon-arrow-down',]"></i>
        </a>
        <a @click="addjihemath(obj.toggleflagjihe)">
          几何<i :class="[obj.toggleflagjihe ? 'el-icon-arrow-up' : 'el-icon-arrow-down',]"></i>
        </a>
      </p>
    </div>
  </span>
</template>

<script>
import { matharrall } from "utils/math";
import { deepClone } from "utils/util";

export default {
  data() {
    return {
      // 是否渲染过模板
      isInitTemp: false,
      obj: {
        temps: matharrall,
        // 常用字符
        toggleflagcy: true,
        // 希腊字母
        toggleflagxl: false,
        // 集合与逻辑
        toggleflagjh: false,
        // 几何
        toggleflagjihe: false
      },
      answers: Object.assign({}, this.childAnswers),
      activeIndex: 0
    };
  },
  props: {
    childAnswers: {
      type: Object,
      default: () => {}
    },
    tempIdx: {
      type: Number,
    },
    parentAnswers: {
      type: Array,
      default: () => []
    },
    // latex模板列表
    temps: {
      type: Array,
      default: () => []
    },
  },
  inject: ['app'],
  mounted() {
    this.init()

    let answerElem = this.$refs.child.querySelector('.answer')
    let _answers = deepClone(this.childAnswers)
    this.answerMathField = this.getMq().MathField(answerElem, {
      handlers: {
        edit: () => {
          var enteredMath = this.answerMathField.latex(); // Get entered math in LaTeX format
          _answers.value = enteredMath
          _answers.show = true
          this.$emit('update:childAnswers', _answers)
        },
      },
    });

    if (this.isPad() && this.temps.length) {
      this.$refs.child.addEventListener('textInput', (e) => {
        let data = e.data
        let keycode = data.charCodeAt(0)

        if (keycode === 9) { // 按tab键
          e.preventDefault()
          this.$emit('handleBlur')
          $('.answer .mq-cursor').remove()
        } else {
          if (this.isAndroid()) {
            this.handleSelectedTemp(data)
          } else {
            e.preventDefault()
            this.handleSelectedTemp(data)
          }
        }
      })
    }
  },
  methods: {
    isPad() {
      let pad = navigator.maxTouchPoints > 1
      return pad || this.isAndroid()
    },
    isAndroid() {
      var sua = this.detect.parse(navigator.userAgent);
      return sua.os.family === 'Android' && sua.device.type === 'Desktop'
    },
    handleInputFocus() {
      let  _parentAnswers = deepClone(this.parentAnswers)
      _parentAnswers = _parentAnswers.map(v => ({...v, show: false}))
      this.$emit('update:parentAnswers', _parentAnswers)

      let _answers = deepClone(this.childAnswers)
      _answers.show = true
      this.$emit('update:childAnswers', _answers)
    },
    handleInputBlur() {
      this.$emit('handleBlur')
    },
    handleSelected(val) {
      this.activeIndex = val

      if (val === 0) { // 常用字符
        let size = this.obj.temps.length
        let _temps = this.obj.temps.concat()

        _temps.forEach((val, index) => {
          if (index == size - 3 || index == size - 2 || index == size - 1) {
            this.$set(val, "eq", false);
          } else {
            this.$set(val, "eq", true);
          }
        });
        this.obj.temps = _temps
      } else if (val === 1) { // 希腊字母
        let size = this.obj.temps.length
        let _temps = this.obj.temps.concat()

        _temps.forEach((val, index) => {
          if (index == size - 4 || index == size - 2 || index == size - 1) {
            this.$set(val, "eq", false);
          } else {
            this.$set(val, "eq", true);
          }
        });
        this.obj.temps = _temps
      } else if (val === 2) { // 集合与逻辑
        let size = this.obj.temps.length
        let _temps = this.obj.temps.concat()

        _temps.forEach((val, index) => {
          if (index == size - 4 || index == size - 3 || index == size - 1) {
            this.$set(val, "eq", false);
          } else {
            this.$set(val, "eq", true);
          }
        });
        this.obj.temps = _temps
      } else if (val === 3) { // 几何
        let size = this.obj.temps.length
        let _temps = this.obj.temps.concat()

        _temps.forEach((val, index) => {
          if (index == size - 4 || index == size - 3 || index == size - 2) {
            this.$set(val, "eq", false);
          } else {
            this.$set(val, "eq", true);
          }
        });
        this.obj.temps = _temps
      }
    },
    init() {
      let size = this.obj.temps.length;
      this.obj.temps.forEach((val, index) => {
        val.eq = true;
        if (index == size - 1 || index == size - 2 || index == size - 3) {
          val.eq = false;
        }
      });
      this.obj.toggleflagxl = false
      this.obj.toggleflagjh = false
      this.obj.toggleflagjihe = false
    },
    /**渲染latex */
    initTemp(index) {
      if (this.isInitTemp) {
        return
      }
      this.isInitTemp = true
      this.$nextTick(() => {
        let elems = this.$refs.child.querySelectorAll('.gap-input-option .m1')
        elems.forEach(v => this.getMq().StaticMath(v))
      })
    },
    getMq() {
      if (!this.MQ) {
        this.MQ = MathQuill.getInterface(2);
      }
      return this.MQ;
    },
    handleFocus() {
      let  _parentAnswers = deepClone(this.parentAnswers)
      _parentAnswers = _parentAnswers.map(v => ({...v, show: false}))
      this.$emit('update:parentAnswers', _parentAnswers)

      let _answers = deepClone(this.childAnswers)
      _answers.show = true
      this.$emit('update:childAnswers', _answers)

      this.initTemp();

      if (!this.app.isPad) {
        let index = this.parentAnswers.filter(v => v.type === 300 || v.type === 400).findIndex(v => v.option_id === this.childAnswers.option_id && v.question_id === this.childAnswers.question_id)
  
        try {
          let offset = $('.gap-input')[index].offsetLeft
          if (offset * 2 + 88 < 488) {
            $('.gap-input-option').css('left', 0).css('transform', 'inherit')
          } else {
            $('.gap-input-option').css('left', '50%').css('transform', 'translateX(-50%)')
          }
        } catch (error) {
          
        }
      }
    },
    /**选择模板 */
    handleSelectedTemp(val) {
      let mathField = this.getMq().MathField(this.$refs.child.querySelector('.answer'));
      mathField.write(val)

      if (val === '^\\circ') { // 当前点击的是度
        mathField.moveToRightEnd().focus()
        // mathField.focus()
      } else { // 其他字符
        let elems = this.$refs.child.querySelectorAll('.answer .mq-root-block [mathquill-command-id]')
        let id, idx = 0, maxId = 0
        elems.forEach((v, i) => {
          id = v.getAttribute('mathquill-command-id')
          if (id > maxId) {
            idx = i
            maxId = id
          }
        })
        $(elems[idx]).mousedown().mouseup()

        let latexs = [
          '\\left(,\\right)',
          '\\left(,\\right]',
          '\\left[,\\right)',
          '\\left[,\\right]',
          '{}\\frac{}{}'
        ]

        if (latexs.indexOf(val) >= 0) {
          mathField.keystroke('Left')
        }

      }

      let originVal = mathField.latex()
      let _answers = deepClone(this.childAnswers)
      _answers.value = originVal
      this.$emit('update:childAnswers', _answers)
    },
    // 点击希腊字母
    addxlmath(toggleflag) {
      let size = this.obj.temps.length
      let _temps = this.obj.temps.concat()

      if (toggleflag) { // 隐藏
        _temps.forEach((val, index) => {
          if (index == size - 3) {
            this.$set(val, "eq", false);
          }
        });
      } else { // 显示
        _temps.forEach((val, index) => {
          if (index == size - 3) {
            this.$set(val, "eq", true);
          }
        });
      }
      this.obj.temps = _temps
      this.obj.toggleflagxl = !toggleflag;
    },
    // 点击集合与逻辑
    addjhmath(toggleflag, ind) {
      let _temps = this.obj.temps.concat()
      let size = this.obj.temps.length
      if (toggleflag) { // 隐藏
        _temps.forEach((val, index) => {
          if (index == size - 2) {
            this.$set(val, "eq", false);
          }
        });
      } else { // 显示
        _temps.forEach((val, index) => {
          if (index == size - 2) {
            this.$set(val, "eq", true);
          }
        });
      }
      this.obj.temps = _temps;
      this.obj.toggleflagjh = !toggleflag;
    },
    // 点击几何
    addjihemath(toggleflag) {
      let _temps = this.obj.temps.concat()
      let size = this.obj.temps.length
      if (toggleflag) { // 隐藏
        _temps.forEach((val, index) => {
          if (index == size - 1) {
            this.$set(val, "eq", false);
          }
        });
      } else { // 显示
        _temps.forEach((val, index) => {
          if (index == size - 1) {
            this.$set(val, "eq", true);
          }
        });
      }
      this.obj.temps = _temps;
      this.obj.toggleflagjihe = !toggleflag;
    },
  }
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: 500px;
  margin: 50px auto;
  justify-content: space-around;
}
.gap-input {
  position: relative;
  margin: 0 5px;
  .input-container {
    min-width: 88px;
    display: inline-block;
    position: relative;
    span {
      display: inline-block;
      padding: 0 5px;
    }
    .input {
      position: absolute;
      left: 0;
      width: 100%;
      border: none;
      border-bottom: 1px solid gray;
      text-align: center;
      height: 22px;
      outline: none;
      font-size: 18.4px;
      color: #555;
      font-family: Symbola, "Times New Roman", serif;
    }
  }
  .answer {
    min-width: 228px;
    min-width: 88px;
    border: none;
    border-bottom: 1px solid gray;
    text-align: center;
    padding: 0 5px;
    box-sizing: border-box;
    box-shadow: none;
    min-height: 22px;
    display: inline-block;
  }
  .gap-input-option {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    border-radius: 10px;
    background: #d5d8de;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 12px;
    .gap-left {
      position: absolute;
      left: -33px;
      width: 30px;
      height: 100%;
      writing-mode: vertical-lr;
      font-size: 14px;
      color: #333;
      span {
        background: #B5BCCC;
        height: 84px;
        width: 30px;
        padding: 0;
        min-width: 30px;
        &:first-child {
          writing-mode: initial;
          height: 37px;
        }
        &.active {
          background: #1F51DE;
          color: white;
        }
        &:last-child {
          height: min-content;
          padding: 0 5px;
          box-sizing: border-box;
          writing-mode: horizontal-tb;
        }
      }
    }
    & > img {
      width: 30px;
      height: 30px;
      position: sticky;
      left: 0;
      top: 23px;
    }
    &.isPad {
      position: fixed;
      right: 0;
      top: 0;
      width: 464px;
      // min-height: 340px;
      min-height: 350px;
      overflow-y: auto;
      overflow: visible;
      left: auto;
      transform: inherit;
      margin-top: auto;
    }
    .sjx {
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      border-left: 1px solid rgb(222, 222, 222);
      border-top: 1px solid rgb(222, 222, 222);
      background: transparent;
      position: absolute;
      left: 48%;
      transform: rotate(45deg) translateX(-50%);
      top: -2px;
      background: white;
      display: none;
    }
    & > div > span {
      color: #555;
      font-size: 15px;
      border-radius: 6px;
      cursor: pointer;
      min-width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 3px;
      font-weight: bold;
      background: #ffffff;
      box-shadow: 0 1px 0px #8e98ac;
      height: 35px;
      width: 68px;
    }
    .latexBox {
      width: 468px;
      flex-wrap: wrap;
      padding: 0 10px;
      &:first-child {
        padding: 10px 10px 0;
      }
      &.isPad {
        padding-bottom: 5px;
        padding-top: 5px;
        align-content: flex-start;
      }
    }
    .xlmathbtn {
      padding: 0 10px 10px;
    }
  }
}
</style>